import React, { useEffect, useState } from "react";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";

{/*
    PURPOSE:
        The purpose of this script is to call S3 and grab data from them.
        This script is not designed to list files within buckets, nor significantly call
        any APIs. It is simply designed to grab JSONs from S3 and call in functions from a higher component 
*/}

async function getRes(bucket, s3Key) {

    //TODO: abstract these credentials from repository
    const credentials = {
      aws_access_key_id: "AKIAXHGC4TJHYPJEWU4D",
      aws_secret_access_key: "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",
    };

    const s3 = new S3Client({
      region: "us-east-1",
      credentials: {
        accessKeyId: credentials.aws_access_key_id,
        secretAccessKey: credentials.aws_secret_access_key,
      },
    });

    let command = new GetObjectCommand({
      Bucket: bucket,
      Key: s3Key,
    });

    try {
      let response = await s3.send(command);
      response = await response.Body.transformToString();
      return await JSON.parse(response ?? "");
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  async function S3Runner(calls,func) {
    let resp = {};

    for (const [Bkey, Bvalue] of Object.entries(calls)) {
      let a = {};
      for (const [Kkey, Kvalue] of Object.entries(Bvalue)) {
        a[Kkey] = await getRes(Bkey,Kvalue)
      }
      resp[Bkey] = a;
    }
    func(resp)
    
  }

export default S3Runner;