import React, { useState, useEffect } from 'react';
import AutoBarChart from '../AutoBarChart';
import FedChart from "../FedChart"
import PivotChart from '../AutoPivotChart';
{/*
  PURPOSE:
    This handles all the differrent types of parsing and what exactly needs to be done to the chart itself
    DO NOT put any type of spacing or other specifiers in this component as it is only used to parse the data then pass it on to the next chart


  NOTES:
    Ideally, there should be a full dictionary and determination system in the future to determine how exactly this wants to work.
  TODO:

  BUGS:
*/}

{/*******  TYPES OF CHARTS  ******/}

// For finance bar charts MIXED CHARTS
function FinanceChart (data) {
    let ans = [];

    //converts the cash numbers to floats that you can actually use
    function cashToInt(numstr) {
        // Remove the dollar sign and commas
        numstr = numstr.replace("$", "").replace(/,/g, "").replace("(","").replace(")","");
        // Parse the string to a float
        return parseFloat(numstr);
    }

    function isNumeric(str) {
        let s = str.replace("$", "").replace(/,/g, "").replace("(","").replace(")","");
        if (typeof s != "string") return false // we only process strings!  
        return !isNaN(s) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(s)) // ...and ensure strings of whitespace fail
    }

    for (let i = 0; i < data.length; i++) {
        let curr = {};
        let perKey = data[i];
        let flag = true;
        for (let j = 0; j < Object.keys(perKey).length; j++) {
            let entryName = Object.keys(perKey)[j]
            let perEntry = data[i][entryName]
            
            if (j==0) {//Title label
                curr.name = perEntry
            }
            else if (j != 0 && perEntry == "") {//blank entry all together
                curr = {};
                flag = false;
            }
            else if (!isNumeric(perEntry)) {//If the entry is blank, or is not numeric
                curr[entryName] = 0;
            }
            else if (entryName != "FY 2022 Total" && entryName != "FY 2023 Total ") {//If it meets all the criteria
                curr[entryName] = cashToInt(perEntry);
            }
        }
        //console.log("CURR:",curr)
        flag && ans.push(curr)
    }
    return ans;
}


function MilitaryChart (data) {
    return data;

}

function BranchParse (data) {
    //pseudo: 
    /*
        1. Get all the agencies first.
        2. Get them all by year as well
        3. Then sort each array
    */
   let a = []

    //These are the different branches... sorry for the one liner
    let branch = [...new Set([...new Set(Object.keys(data["data"][0]).filter(e => e != "name"))].map(perOb => (perOb.split("-")[0])))]
    
    for (let i = data["data"].length - 1; i >= 0; i--) {
        for (let j = 0; j < branch.length; j++) {
            let entry = {date:data["data"][i]["name"], "branch":branch[j]}
            
            let categories = [...new Set(Object.keys(data["data"][i]))].filter(e => e != "name" && e.startsWith(entry["branch"])).map(perOb => (perOb.split("-")[1]))
            
            categories.map(perCat => (
                entry[perCat] = data["data"][i][entry["branch"]+"-"+perCat]
            ))


            a.push(entry)
        }
    }
   
   return {...data, "data":a};
}

const Determiner = ({data, parse}) => {
    switch(parse) {
        case "Finance":
            return FinanceChart(data)
        case "Military":
            return MilitaryChart(data)
        case "Branch":
            return BranchParse(data)
        default:
            return data;
    }
}

const SuperQuick = ({formattedData, parse, palette, docSource}) => {
        switch(parse) {
            case "Finance":
                return (<AutoBarChart data={formattedData} palette={palette} x={1800} docSource={docSource}/>)
            case "Military":
                return (<FedChart data={formattedData} palette={palette} x={1800} docSource={docSource} />)
            case "Branch":
                return (<PivotChart data={formattedData} palette={palette} x={1800} docSource={docSource} />)
            default:
                return formattedData;
        }
}

const BarParse = ({data, parse, palette, docSource}) => {
    const [formattedData, setFormattedData] = useState(Determiner({data,parse}));
    useEffect(() => {
        setFormattedData(Determiner({data,parse}));
      }, [data]);
    return (
            <div>
                <SuperQuick formattedData={formattedData} parse={parse} palette={palette} docSource={docSource} />
                
            </div>
    )
}

export default BarParse;