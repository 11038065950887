import React from "react";
import { Chart } from "react-google-charts";
import SourceIcon from "@mui/icons-material/Source";
import "./autoGanttChart.css";
import Stack from "@mui/material/Stack";
import SourceButton from "../utils/SourceButton";
const AutoGanttChart = ({ data, columns, palette, docSource }) => {
  const options = {
    height: 350,
    gantt: {
      trackHeight: 20,
      palette: palette,
      barCornerRadius: "80%",
      labelStyle: {
        fontName: "Source Sans Pro",
        fontSize: 12,
        color: "#757575",
        barHeight: ".5px",
      },
      innerGridHorizLine: { strokeWidth: ".5px" },
      criticalPathEnabled: false,
      //labelMaxWidth: 300,
      shadowEnabled: false,
    },
    timeline: {
      showRowLabels: true,
      showBarLabels: false,
      colorByRowLabel: true,
      singleColor: "#8d8",
    },
    annotations: {
      style: "line",
      stem: {
        color: "#097138",
      },
    },
  };
 
  const data_shift = [columns, ...data];
 
  return (
    <>
      <div className="chart-container">
        <Chart
          chartType="Gantt"
          width="100%"
          // height="30%"
          data={data_shift}
          options={options}
        />
 
        {/* <div className="mt-[-35px] ">
          <Stack className="bar-chart-label" direction="row" spacing={1}>
            <SourceIcon fontSize="small" />
            <a
              href={`${docSource}`}
              target="_blank"
              style={{ color:  "#1A0DAB"}}
              className="bar-chart-label items-end "
            >
              source: dla wms
            </a>
          </Stack>
        </div> */}
                <SourceButton docSource={docSource} text={"dla wms"}/>

      </div>
    </>
  );
};
 
export default AutoGanttChart;