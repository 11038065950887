import React, { PureComponent } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const testData = [
  {
    "date": '2000-01',
    OTHER: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    "date": '2000-02',
    OTHER: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    "date": '2000-03',
    OTHER: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    "date": '2000-04',
    OTHER: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    date: '2000-05',
    OTHER: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    date: '2000-06',
    OTHER: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    date: '2000-07',
    OTHER: 3490,
    pv: 4300,
    amt: 2100,
  },
  {
    date: '2000-08',
    OTHER: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    date: '2000-09',
    OTHER: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    date: '2000-10',
    OTHER: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    date: '2000-11',
    OTHER: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    date: '2000-12',
    OTHER: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    date: '2000-11',
    OTHER: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    date: '2000-12',
    OTHER: 1890,
    pv: 4800,
    amt: 2181,
  },
];

const monthTickFormatter = (tick) => {
  return tick
};

const renderQuarterTick = (tickProps) => {
  const { x, y, payload } = tickProps;
  const { value, offset, index } = payload;
  const month = index;
  
  if ((index + 1) % 5 == 3) {
    return <text x={x} y={y + 80} textAnchor="middle">{`${value}`}</text>
  }
  else if ((index + 1) % 5 == 1) {
    const pathX = Math.floor( x - offset) + 0.5;

    return <path d={`M${pathX},${y - 4}v${-35}`} stroke="gray" />;
  }
  return null;

};
const PivotChart = ({data,palette}) => {

  return (
        <ResponsiveContainer
        // width="80%"
        aspect={3}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        
        <BarChart
          width={10000}
          height={250}
          data={data["data"]}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 50,
          }}
          overflow="visible"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="branch" 
            angle={-90} 
            textAnchor={"end"} 
            interval={0}
            dy={5} 
            tickFormatter={monthTickFormatter} />
          <XAxis
            dataKey={"date"}
            axisLine={false}
            tickLine={false}
            interval={0}
            tick={renderQuarterTick}
            height={1}
            xAxisId="quarter"
          />
          <YAxis tickFormatter={(tick) => {
              return (
                "$" +
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(tick)
              );
            }}/>
          <Tooltip formatter={(value) => `$${value}`} />
          <Legend layout="horizontal" verticalAlign="top" align="center" height={36}/>
          <Bar dataKey={"GSA"} stackId={"a"} fill={palette[1]} />
          <Bar dataKey={"SMS"} stackId={"a"} fill={palette[2]} />
          <Bar dataKey={"OTHER"}  stackId={"a"} fill={palette[3]} />
        </BarChart>
      </ResponsiveContainer>

  );

};
 
export default PivotChart;

 