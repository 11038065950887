import React from "react";
import "../styles/global_styles.css";

import Navbar from "./navBar/entryNavBar";
import { deepAccessLinks } from "../data/deppAccessLinks";
import { useNavigate } from "react-router";

const ReportPage = ({ layout, selectedTopic }) => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar />

      <div className="mx-[10px] lg:mx-[120px] mt-6 ">
        <div className="flex flex-col w-full justify-center items-center gap-1 mt-8 ">
          <p className="text-[30px] pt-2">SITREP</p>
          <div className="flex gap-4 flex-wrap justify-center pb-3">
            {Object.keys(layout)?.map((topic, index) => {
              return (
                <div
                  className="w-[394px] h-[209px] justify-center relative flex items-end cursor-pointer bg-cover bg-center  transition-transform duration-300"
                  style={{
                    backgroundImage: `url(assets/${layout[topic].img})`,
                  }}
                  onClick={() => {
                    selectedTopic[1](topic);
                    navigate(`/dashboard?topic=${topic}`);
                  }}
                >
                  <div className="absolute top-0 left-0 w-full h-full bg-[#133c6a] bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 z-0"></div>
                  <span className="m-3 text-[25px] text-white relative z-10">
                    {topic}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex flex-col w-full justify-center items-center gap-1 mt-8 ">
          <p className="text-[30px] pt-2">CHAT</p>
          <div className="flex gap-4 flex-wrap justify-center pb-3">
            {deepAccessLinks?.map((topic) => {
              return (
                <a href={topic?.link} target="_blank">
                  <div
                    className=" w-[230px] h-[156px] justify-center relative flex items-end cursor-pointer bg-cover bg-center  transition-transform duration-300"
                    style={{
                      backgroundImage: `url(assets/${topic?.img})`,
                    }}
                  >
                    <div className="absolute top-0 left-0 w-full h-full bg-[#9b2743] bg-opacity-50 hover:bg-opacity-0 transition-opacity duration-300 z-0"></div>
                    <span className="m-3 text-[25px] text-white relative z-10">
                      {topic?.title}
                    </span>
                  </div>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportPage;
