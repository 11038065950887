import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';

{/*
  PURPOSE:
    The purpose of this component is to have a general "entry page".
    This is the first page that pops up when you start the react app
    This page is desigend to pick any parameters which are needed before you make any significant API
    calls for data retrival. In this version of the code (6/28/24) you pick only the period of date
    as well as the topic at hand from the layout. 
*/}


//Generic dropdown menu - label - label of dropdown, options - list of options - stat selection that should be used
const Dropdowns = ({ label, options, stat }) => {

  const handleChange = (event) => {
    stat[1](event.target.value);
  };

  return (
    <Box sx={{display:"flex",flexDirection:"row"}}>
      <InputLabel variant="standard" htmlFor="uncontrolled-native" style={{marginTop:"5px"}}>
        {label} {/* Use the label prop here to make the label dynamic */}
      </InputLabel>&nbsp;&nbsp;
      <NativeSelect
        inputProps={{
          name: label,
          id: 'uncontrolled-native',
        }}
        value={stat[0]}
        onChange={handleChange}
      >
        {
          options.map((perOption, index) => (
            <option key={index} value={perOption}>{perOption}</option>
          ))
        }
      </NativeSelect>
    </Box>
  );
}
{/*
  Report page - General page layout and the only component from this file which should be used externally

  onGenerateReport - Function which is called when you hit the button
  layout - JSON object which is used to dictate the format of the website
  selected Month - Selects which month should be used - format is [webHook, setWebhookFunction] 
  selected Topic - Selects which topic should be used - format is [webHook, setWebhookFunction] 
]
*/}
const DropDate = ({  options, selectedMonth,label }) => {

  return (
   <>
        <div>
          <Dropdowns label={"Select Period"} options={options} stat={selectedMonth} />
        </div>
       

        
        </>
      
  );
};

export default DropDate;

