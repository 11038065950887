import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Navbar from "./navBar/navBar";
import { CardIter as CardIter } from "./cardHandlers/CardIter.js";
import S3Runner from "./utils/S3Runner.js";
import { useLocation, useNavigate } from "react-router-dom"; 

import "../data/Layout.json";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function DashboardContent({ lay, selectedMonth }) {
  const [textHolder, setTextHolder] = useState();
  const [editMode, setEditMode] = useState(false);

  const query = useQuery();
  const navigate = useNavigate(); 
  const selectedTopic = query.get("topic");

  useEffect(() => {
    const monthParam = selectedMonth[0];
    if (selectedTopic && monthParam) {
      query.set("month", monthParam);
      navigate({ search: query.toString() }, { replace: true });
    }

    S3Runner(lay[selectedTopic][monthParam], setTextHolder);
  }, [selectedMonth, selectedTopic, lay]);

  return (
    <>
      <Navbar
        topic={selectedTopic}
        selectedMonth={selectedMonth}
        options={Object.keys(lay[selectedTopic]).filter(e => e !== "img" && e !== "layout")}
      />
      {textHolder ? (
        <CardIter layout={lay[selectedTopic]["layout"]} data={textHolder} />
      ) : (
        <Box sx={{ width: "100%" }}>
          <LinearProgress sx={{ backgroundColor: "#133c6a", '& .MuiLinearProgress-bar': { backgroundColor: "lightblue" } }} />
        </Box>
      )}
    </>
  );
}

