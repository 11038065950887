import '../card-content.css';

function FormatText({ text }) {
  console.log('Received text:', text);

  // Ensure text is a string
  const textString = typeof text === 'string' ? text : '';

  if (textString.startsWith('- ')) {
    return (
      <div style={{display:'flex',flexDirection:'row'}}>
               <div style={{ fontSize: '0.97rem' }}>• </div>&nbsp;
        <div style={{ fontSize: '0.97rem' }}>{textString.substring(2)}</div>
      </div>
    );
  } else {
    return (
      <div style={{display:'flex',flexDirection:'row'}}>
        <div style={{ fontSize: '0.97rem' }}>• </div>&nbsp;
        <div style={{ fontSize: '0.97rem' }}>{textString}</div>
      </div>
    );
  }
}

export default FormatText;
