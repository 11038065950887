import React from 'react';
import { Grid, Card, CardContent } from '@mui/material';
import FormatText from '../utils/textFormat.js';
import './textCard.css'
import SourceButton from '../utils/SourceButton.js';
 
{/*
  PURPOSE:
    This is the generic text card, if props.text is an array, then we put it in a bullet point format,
    Otherwiese, the text is laid out in a paragraph
*/}
 
const TextCard = ({props}) => {
  return (
            <Grid item  sx={{ display: 'flex', justifyContent: 'center' }}>
              <Card sx={{ width: '100%',padding:"5px" }}>
                <CardContent sx={{maxHeight: "13rem", overflowY: 'auto',"&:last-child": {paddingBottom:2}}}>
                    {Array.isArray(props.text) ? props.text.map((card,index) => (<FormatText key={index} text={card} />)) : props.text}
                    {props["source"] ? <SourceButton text={props.title} docSource={props["source"]} /> : null}

                </CardContent>
              </Card>
            </Grid>
  )
};
 
export default TextCard;
 