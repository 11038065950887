import React, {useEffect} from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import "./autoBarChart.css";
import Stack from "@mui/material/Stack";

import SourceIcon from "@mui/icons-material/Source";
import SourceButton from "../utils/SourceButton";
{
  /*
  PURPOSE:
    The purpose of this script is to have an easy way to display data in a bar chart.
    DO NOT parse the data within this chart 
  NOTES:

  TODO:
    
  BUGS:

*/
}

const AutoBarChart = ({ data, x, palette, docSource }) => {
  function tickFormatter(value) {
    let index = 10;
    const limit = 20; // put your maximum character
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
  }

  // Custom label component
  const CustomLabel = ({ x, y, width, height, value }) => {
    {
      /* to covert the numbers in smaller format */
    }
    const displayValue =
      value > 5000000
        ? "$" +
          new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
          }).format(value)
        : null;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="white"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {displayValue}
      </text>
    );
  };

  useEffect(() => {
    console.log(docSource);
  }, [docSource])

  return (
    <>
      <ResponsiveContainer
        // width="80%"
        aspect={3}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >

        <BarChart
          width={x ? x : 500}
          height={150}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          overflow="visible"
        >
          <Legend layout="horizontal" verticalAlign="top" align="center" height={36}/>
          
          <CartesianGrid strokeDasharray="3 0" vertical={false} />

          <XAxis
            dataKey={"name"}
            angle={-20}
            interval={0}
            textAnchor="end"
            tickFormatter={tickFormatter}
          />
          {/* to covert the numbers in smaller format */}
          <YAxis
            tickFormatter={(tick) => {
              return (
                "$" +
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(tick)
              );
            }}
          />

          <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />

          {Object.keys(data[0]).map((val, ind) => {
            return val != x && ind != 0 ? (
              <Bar
                key={ind}
                dataKey={val}
                fill={palette[ind % palette.length]}
                barSize={300}
                activeBar={<Rectangle fill="pink" stroke="blue" />}
                stackId="a"
              >
                <LabelList dataKey={val} content={<CustomLabel dy={-10} />} />
              </Bar>
            ) : null;
          })}
        </BarChart>
        {/* <div className="mt-[0px] ">
        <Stack className="bar-chart-label" direction="row" spacing={1}>
          <SourceIcon fontSize="small" />
          <a
            href={`${docSource}`} 
            target="_blank"
            style={{ color: "#1A0DAB" }}
            className="bar-chart-label items-end "
          >
            source: dla consolidated afr
          </a>
        </Stack>
        </div> */}
        <SourceButton docSource={docSource} text={"dla consolidated afr"}/>
      </ResponsiveContainer>
    </>
  );
};

export default AutoBarChart;
