export const deepAccessLinks = [

  {
    title: "Critical Capabilities",
    link: "https://deepaccess.ilabs.credence-llc.com/chat?appType=0&useCase=DLA%20-%20ARTET&mode=0",
    img: "ll_2.png",
  },
 
  {
    title: "FedMall",
    link: "https://deepaccess.ilabs.credence-llc.com/chat?appType=1&useCase=DLA%20-%20ARTET&mode=2",
    img: "fedmall_2.png",
  },
  {
    title: "Lines of Effort",
    link: "https://deepaccess.ilabs.credence-llc.com/chat?appType=0&useCase=DLA%20-%20ARTET&mode=1",
    img: "cc_2.png",
  },
  
  {
    title: "OPM",
    link: "https://deepaccess.ilabs.credence-llc.com/chat?appType=1&useCase=DLA%20-%20ARTET&mode=1",
    img: "opm.png",
  },
  {
    title: "Financial",
    link: "https://deepaccess.ilabs.credence-llc.com/chat?appType=1&useCase=DLA%20-%20ARTET&mode=0",
    img: "opm.png",
  },
];
