import React from 'react'
import SourceIcon from "@mui/icons-material/Source";
import Stack from "@mui/material/Stack"

function SourceButton({text, docSource}) {
  return (
<div className="mt-[-10px] ">
          <Stack className="bar-chart-label" direction="row" spacing={1}>
            <SourceIcon fontSize="small" />
            <a
              href={`${docSource}`}
              target="_blank"
              style={{ color:  "#1A0DAB"}}
              className="bar-chart-label items-end "
            >
              source: {text}
            </a>
          </Stack>
        </div>  
        )
}

export default SourceButton