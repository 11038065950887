import React, { useState, useEffect } from 'react';
import AutoGanttChart from '../AutoGanttChart';

{/*
  PURPOSE:
    This handles all the differrent types of parsing and what exactly needs to be done to the gantt chart itself
    DO NOT put any type of spacing or other specifiers in this component as it is only used to parse the data then pass it on to the next chart

  NOTES:

  TODO:
    

  BUGS:
*/}

const CalendarScrape = (data) => {

    const key_translate = {
        "title" : "Task ID",
        "tooltip" : "Task Description"
    }

    const month_translate = ["January","February","March","April","May","June","July","August","September","October","November","December"]

    const col = [
        { type: "string", label: "Task ID" },
        { type: "string", label: "Task Name" },
        { type: "string", label: "Resource" },
        { type: "date", label: "Start Date" },
        { type: "date", label: "End Date" },
        { type: "number", label: "Duration" },
        { type: "number", label: "Percent Complete" },
        { type: "string", label: "Dependencies" },
    ];

    function stringToDate(samp) {
        let quick = samp.split("-")
        return new Date(parseInt(quick[0]),month_translate.indexOf(quick[1]),parseInt(quick[2]))
    }
    
    let ans = [];
    let locations = [];
    let dates = [];


    //NOTE: THIS IS EXTREMELY HARDCODED AT THE MOMENT TO ONLY TAKE IN EXACTLY AS NEEDED FROM THE CALENDAR DATA. DO NOT USE IN ANY OTHER SITUATION
    //pseudo: 
    /*
      1. list of locations
      2. list of start dates and end dates
      3. (update the end dates every time they are seen on the map)
      4. THEN create all of the records into a final working gantt chart format
    */

    Object.keys(data).forEach((perRow) => {
      if (perRow.includes("month_")) {
        const rowData = data[perRow];
        // find the location in the cache
        // perDay - iterates the name of the day by direct object iteration
        // ind - index of the object (used for dates)
        rowData["schedule"].map((perDay,ind) => {
          let loc = locations.indexOf(perDay['location'])
          let dat = stringToDate(perDay['date'])
          if (locations.includes(perDay['location'])) {
            if (dates[loc][0] > dat) {
              dates[loc][0] = dat
            }
            else if (dates[loc][1] < dat) {
              dates[loc][1] = dat
            }
          }
          else {
            locations.push(perDay['location'])
            dates.push([dat,dat])
          }
        })
      }
    })

    locations.forEach((perEntry,ind) => {
      let a = [perEntry,perEntry,perEntry,dates[ind][0],dates[ind][1],null,0,null]
      ans.push(a)
    })

    return [ans, col]
}


const Determiner = ({data, parse}) => {
    switch(parse) {
        case "CalendarScrape":
            return CalendarScrape(data)
        default:
            return data;
    }
}

const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];
  

const GanttChartParse = ({data,parse,palette, docSource}) => {
    const [formattedData, setFormattedData] = useState(Determiner({data,parse}));

    return (
        <AutoGanttChart data={formattedData[0]} columns={columns} palette={palette} docSource={docSource}/> 
    )
}

export default GanttChartParse;