import React, {useEffect} from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import "./autoBarChart.css";
import Stack from "@mui/material/Stack";

import SourceIcon from "@mui/icons-material/Source";
{
  /*
  PURPOSE:
    The purpose of this script is to have an easy way to display data in a bar chart.
    DO NOT parse the data within this chart 
  NOTES:

  TODO:
    
  BUGS:

*/
}

const FedChart = ({ data, x, palette, docSource }) => {

  function tickFormatter(value) {
    let index = 10;
    const limit = 15; // put your maximum character
    if (value.length < limit) return value;
    return `${value.substring(0, limit)}...`;
  }

  function secFormat(value) {
    return value;
  }
  
  // Custom label component
  const CustomLabel = ({ x, y, width, height, value }) => {
    /* to covert the numbers in smaller format */
    const displayValue =
      value > 5000000
        ? "$" +
          new Intl.NumberFormat("en-US", {
            notation: "compact",
            compactDisplay: "short",
          }).format(value)
        : null;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="black"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {displayValue}
      </text>
    );
  };



  return (
    <>
          <ResponsiveContainer
        // width="80%"
        aspect={3}
        margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
      >
        <BarChart
          width={x ? x : 500}
          height={150}
          data={data["data"]}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          overflow="visible"
        >
          
          <CartesianGrid strokeDasharray="3 0" vertical={false} />


          <XAxis
            dataKey={"label"}
            angle={-24}
            fontSize={14}
            textAnchor="end"
            interval={0}
            tickFormatter={tickFormatter}
          />
          
          
          <YAxis tickFormatter={(tick) => {
              return (
                "$" +
                new Intl.NumberFormat("en-US", {
                  notation: "compact",
                  compactDisplay: "short",
                }).format(tick)
              );
            }}/>
          <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
          
            <Bar
            dataKey={"value"}
            fill={palette[1 % palette.length]}
            barSize={300}
           
            activeBar={<Rectangle fill="pink" stroke="blue" />}
            />

        </BarChart>

      </ResponsiveContainer>
    </>
  );
  //
};

export default FedChart;