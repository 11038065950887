import React, {useState} from "react";
import DLALogo from "../../Seal_of_the_Defense_Logistics_Agency.png";
import DropDate from "./DropDate";

const Navbar = ({ topic,selectedMonth, options }) => {
  const currentDate = new Date().toDateString();
  
  console.log("title", topic);

  return (
    <div>
      <div className="flex flex-wrap justify-between mx-[60px] items-center py-4">
        <div>
          <div className="flex flex-row">
            <div className=" mt-2 text-[#335d8b] text-[22px]">
              <div
                className="text-[#335d8b] text-[30px] mr-4"
                style={{
                  fontFamily: "Times New Roman, Times, serif",
                  fontVariantCaps: "small-caps",
                }}
              >
                {topic} Sitrep
              </div>
              <div
                className="text-[#335d8b] text-[22px]"
                style={{
                  fontFamily: "Times New Roman, Times, serif",
                }}
              >
                {/* {currentDate} */}
                <DropDate  selectedMonth={selectedMonth} label={"Select Date"} options={options}/>
              </div>
            </div>
          </div>
        </div>
        {/* <img className="h-[30px]" src={CredenceLogo} alt="Credence Logo" /> */}

        <div>
          <div className="flex flex-row">
            <div className="justify-center">
              <img
                src={DLALogo}
                alt="DLA Logo"
                style={{ height: "64px", width: "80px", marginRight: "10px" }}
              />
            </div>
            <div
              className="text-left mt-0"
              style={{
                fontVariantCaps: "small-caps",
              }}
            >
              <div
                style={{
                  color: "#335d8b",
                  fontSize: "30px",
                  fontFamily: "Times New Roman, Times, serif",
                }}
                className="hidden sm:block"
              >
                Defense Logistics Agency
              </div>
              <div
                style={{
                  color: "#335d8b",
                  fontSize: "30px",
                  fontFamily: "Times New Roman, Times, serif",
                }}
                className="block sm:hidden"
              >
                DLA
              </div>
              <div
                style={{
                  color: "#2A7DA7",
                  fontSize: "18px",
                  fontFamily: "Times New Roman, Times, serif",
                }}
                className="hidden sm:block"
              >
                The Nation's Combat Logistics Support Agency
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-gray-400 py-2 text-start px-20 flex-row ">
        <div className="text-[18px]">
        {topic}
        </div>
      </div> */}
    </div>
  );
};

export default Navbar;

