import "./App.css";
import EntryPage from "./components/EntryPage";
import DashboardContent from "./components/DashboardHandler";
import React, { useState } from "react";
import layout from "./data/Layout.json";
import { Route, Routes } from "react-router";

function App() {
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedMonth, setSelectedMonth] = useState('June 2024')

  return (
    <div className="app">
      <Routes>
        <Route
          path="/"
          element={
            <EntryPage
              layout={layout}
              selectedTopic={[selectedTopic, setSelectedTopic]}
            />
          }
        />

        <Route
          path="/dashboard"
          element={
            <DashboardContent  selectedMonth={[selectedMonth, setSelectedMonth]} selectedTopic={selectedTopic} lay={layout} />
          }
        />
      </Routes>
    </div>
  );
}

export default App;
