import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";

import FormatText from "../utils/textFormat";
import BarParse from "../chartHandlers/chartParsers/BarParse";
import GanttChartParse from "../chartHandlers/chartParsers/GanttChartParse";

{
  /*
  PURPOSE:
    The purpose of this component is to have a single type of graphing component - should not matter
    what type of graph, just outlines each compnent into a 2/3 - 1/3 format

*/
}

// p - props - props.props contains all arguments and data needed to generate this component
const GraphCard = (p) => {
  const palette = [
    "#5A5C66",
    "#208DA7",
    "#F05053",
    "#0D4B5C",
    "#0D4B5C",
    "#5C102B",
    "#A69B34",
    "#60723C",
  ];

  let props = p.props;

  const graphSelector = (type, dataSource, parse, docSource) => {
    switch (type) {
      case "bar":
        return (
          <BarParse
            data={dataSource}
            parse={parse}
            palette={palette}
            docSource={docSource}
          />
        );
      case "gantt":
        return (
          <GanttChartParse
            data={dataSource}
            parse={parse}
            palette={palette}
            docSource={docSource}
          />
        );
      default:
        return <p>invalid</p>;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Grid item xs={12} lg={8}>
        <Card sx={{ width: "100%", padding: 2 }}>
          <CardContent>
            {graphSelector(props.type, props.data, props.parse, props.source)}
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Card sx={{ width: "100%", padding: 0 }}>
          <CardContent>
            {/* <Typography sx={{ fontFamily: "Times New Roman, Times, serif" }}>
              Key Highlights
            </Typography> */}
            
            <div  style={{fontSize: "1.1rem", paddingBottom:"5px"}}>
              Key Highlights
            </div>
            {Array.isArray(props.insightSource)
              ? props.insightSource.map((card) => <FormatText text={card} />)
              : props.insightSource}
            <Typography></Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GraphCard;
