import React from "react";
import CredenceLogo from "../../credence_1.png";
import DLALogo from "../../Seal_of_the_Defense_Logistics_Agency.png";

const Navbar = () => {
  return (
    <div>
      <div className="flex flex-wrap justify-between mx-28 items-center py-4">
        <div>
          <div className="flex flex-row">
            <div className="justify-center ">
              <img
                // className=" h-[50px] mr-[10px]"
                src={DLALogo}
                alt="DLA Logo"
                style={{ height: "64px",width:"80px", marginRight: "10px" }}

              />
            </div>
            <div className="text-left mt-2 text-[#335d8b] text-[30px] ">
              <div
                style={{
                  fontFamily: "Times New Roman, Times, serif",
                }}
              >
                {/* DLA.AI */}
              </div>
            </div>
          </div>
        </div>
        <img className="h-[40px]" src={CredenceLogo} alt="Credence Logo" 
        
        />
      </div>
      <div className="bg-gray-400 py-4 text-start px-20 flex-row ">
        <div className="text-[18px]"></div>
      </div>
    </div>
  );
};

export default Navbar;
