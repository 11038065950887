import React from "react";
import { Box, Grid, useTheme, useMediaQuery, Paper } from "@mui/material";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "./sliderDeck.css";

const SliderCard = ({ props }) => {
  const items = props;
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up(769));

  return (
    <>
      {isMediumScreen ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {items?.map((item, index) => (
            <Box
              key={index}
              sx={{
                flex: "1",
                m: 0,
                p: 0,
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              {item}
            </Box>
          ))}
        </Box>
      ) : (
        // Slide deck component conditions
        <>
          <Grid container>
            <Grid item xs={12}>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <div className="flex justify-center items-center">
                  <Swiper
                    navigation={true}
                    className="mySwiper"
                    modules={[Navigation, Pagination, Autoplay]}
                    spaceBetween={50}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 7000 }}
                  >
                    {items?.map((item, index) => (
                      <SwiperSlide key={index}>{item}</SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Paper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default SliderCard;
