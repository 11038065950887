import React from "react";
import GraphCard from "./GraphCard.js";
import TextCard from "./TextCard.js";
import SliderCard from "./SlideDeck.js";
import CardFormatter from "./CardFormatter.js";


{/*
  PURPOSE:
    The purpose of this component is to iterate through the layout and the data one card at 
    a time and allocate where the data should go per card
    
  NOTE:
    This is not the most efficient way to do this, but it manages to keep the JSON very easy to view
    as well as abstracts most of the javascript away from the programmer who needs to quickly change
    the dashboard
*/}

function findCorr(points,data) {
    if (Array.isArray(points)) {
        let obj = data;
        for (let t of points) {
            obj = obj[t];
            if (obj === undefined) {
                return points
            }
        }
        return obj;
    }
    else {
        return points;
    }
}

// formatter - all parameters that are required for each component within a JSON - "type" is the only one that is needed no matter what kind of component
const CardType = ({formatter}) => {
    switch (formatter["type"]) {
        case "text":
            return (<TextCard props={formatter}/>)
        case "bar":
            return (<GraphCard props={formatter}/>)
        case "gantt":
            return (<GraphCard props={formatter}/>)
        default:
            return <p>ERROR</p>
    }
}

// layout - the main layout array which we use in order to format the dashboard
// data - this is the data that is passed down to the actual component itself. It should not be passed futher to any further components
// noPadding - this is a boolean which determines if the component below should have any left or right padding - only used recursively inside of other components
const CardIter = ({layout,data,noPadding}) => {
    return (<div>
        { data ?
            layout.map((card, index) => {
                let formatter = {}
                if (card["type"] != "slideDeck") {
                    for (const [perKey, perValue] of Object.entries(card)) {
                        formatter[perKey] = findCorr(perValue,data)
                    }
                    return <CardFormatter key={index} props={<CardType formatter={formatter} />} isSub={true} title={formatter.title} noPadding={noPadding}/>
                }
                else {
                    let cardArray = card.slides.map((perCard,index) => (
                        <CardIter key={index} layout={[perCard]} data={data} noPadding={true} />
                    ))
                    return <CardFormatter key={index} props={<SliderCard props={cardArray} />} title={card.title} />
                }

            }) : null
        }
    </div>)
}

export {CardIter, CardType};