import React from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';

{/*
  PURPOSE:
    The purpose of this component is to have a general format to all components within the dashboard.
    If you change styling in this component at all, it will effect each card type for the entire dashboard!

    props - pass in the react component - yes I'm serious... this is because we don't want to pass down all data via prop drilling
            In a later version, I would like to pass down possibly a useContext here or some other solution but time is short

    title - top band of the card
    
    noPadding - boolean - deteremines if there should be any padding on the left and right of the width of the card
            useful for when you recursively want to have compoennts insie of other components
*/}
const CardFormatter = ({props,title,noPadding, isSub = false}) => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'start',
          padding: 1, // Padding to avoid content touching the edges,
        }}
      >
        <Accordion
          defaultExpanded
          sx={{
            height: noPadding ? null : '100%',
            width: noPadding ? '100%' : '95%', // Adjust the width as needed
            minWidth: '300px', // Optional: minimum width
            color:'white',
            borderRadius:"5px",
            backgroundColor:'#E5E7EB',
          }}
        >
          <AccordionSummary
            sx={{
              minHeight: 'unset', // Disable height adjustment
              transition: 'none', // Disable transition effect
              padding: '8px 16px', // Adjust padding to make the banner smaller
              backgroundColor:'#133c6a',
              '&.Mui-expanded': {
                minHeight: 'unset',
              },
              '.MuiAccordionSummary-content': {
                margin: 0, // Reset margin
              },
              '.MuiAccordionSummary-content.Mui-expanded': {
                margin: 0, // Reset margin for expanded state
              },
              '.MuiTypography-root': {
                fontSize: '0.875rem', // Smaller font size
              },
            }}
          >
            <div style={{ fontSize: isSub? '1rem': '1.1rem'}}>{title}</div>
          </AccordionSummary>
          <AccordionDetails>
              {props}
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  };

  export default CardFormatter;